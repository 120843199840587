.cardl {
    background: #ffffff;
    box-shadow: 0px 5px 8px rgba(216, 227, 241, 0.65);
    border-radius: 8px;
    width: 461px;
}

.fontdesc {
    font-family: 'DM Sans';
    font-style: normal
}

.btnwidth {
    width: 180px;
}

.imgcss {
    max-width: 114px;
}

.chooseroom {
    border-radius: 0 0 8px 0;
}
.cardBtnHover {
    background: linear-gradient(0deg, #292931, #292931),
        linear-gradient(275.36deg, #2f69ff 0%, #c856ff 100%);
}

.cardBtnHover:hover {
    background: linear-gradient(275.36deg, #2f69ff 0%, #c856ff 100%);
}