.filter-heading > span {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #434354;
}
.filter-heading > p {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #2f69ff;
}
.fil {
    border: 1px solid #e1e1e1;
    border-radius: 10px;
}

.fh {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #292931;
}

.fsh {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #646465;
}

input[type="range"]::-webkit-slider-runnable-track {
    background: linear-gradient(275.36deg, #640ae7 0%, #c856ff 100%);
    border-radius: 100px;
    height: 7px;
}

#flexCheckDefault {
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    -ms-appearance: checkbox;
}

@media only screen and (max-width: 600px) {
    .filter {
        display: none;
    }
}
.radiocolor {
    accent-color: #640ae7;
    border-color: #e1e1e1;
}
