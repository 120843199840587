.heading1 {
    font-family: DM Sans;
    font-style: normal;
    line-height: 65px;
    letter-spacing: 0em;
    text-align: center;
}

.tabbar {
    max-width: 1024px;

}
@media only screen and (max-width: 992px) {
    .tabnav{
        display: none;
    }
    .tabbar{
        width: auto;
    }
    .heading1{
        font-size: 20px;
        font-weight: 700;        
        margin-bottom: 20px;
    }
}