.descr {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    color: #646465;
    /* width: 900px; */
}

.hp {
    display: grid;
grid-template-columns: repeat(4, 1fr);
grid-template-rows: 1fr;
    gap: 0.5rem;
}
.hp .div1 {
    grid-area: 1 / 1 / 2 / 4;
}
.hp .div2 {
    grid-area: 1 / 4 / 2 / 5;
}

.startpadding {
    margin-left: 470px;
}
.hpage {
    max-width: 1360px;
}

.hel {
    /* width: 920px; */
    /* height: 139px; */
}

.carousel-indicators [data-bs-target] {
    width: 10px;
    height: 10px;
    border-radius: 100%;
}

.closeBtn {
    margin: 0.5rem 0rem 0.5rem auto;
}

.amtText {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
}

.back {
    margin-top: 64px;
}

.mp {
    right: 0;
}

#more,
#more1 {
    display: none;
}

.parent1 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 0.5rem;
}

.div1 {
    grid-area: 1 / 1 / 3 / 4;
}
.div2 {
    grid-area: 1 / 4 / 2 / 5;
}
.div3 {
    grid-area: 2 / 4 / 3 / 5;
}

@media screen and (max-width: 992px) {
    .parent1 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        gap: 0.5rem;
    }

    .div1 {
        grid-area: 1 / 1 / 3 / 3;
    }
    .div2 {
        grid-area: 3 / 1 / 4 / 2;
    }
    .div3 {
        grid-area: 3 / 2 / 4 / 3;
    }

    .hp {
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (min-width: 992px) {
    .imgmob {
        display: none;
    }
}

@media only screen and (max-width: 992px) {
    .hpage {
        /* width: 360px; */
    }
    .amchng {
        margin-bottom: 0;
    }

    #more,
    #more1 {
        padding-top: 12px;
    }
    .hel {
        width: auto;
    }

    .hhead {
        font-size: 20px;
    }

    .rat-text {
        display: none;
    }

    .descr {
        /* width: 350px; */
        padding-left: 2px;
    }
    .startpadding {
        margin-left: 100px;
    }
    .ami {
        /* width: 350px; */
        height: 100%;
    }

    .back {
        margin-top: 10px;
    }
}
