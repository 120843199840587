.hcard {
    background: #ffffff;
    box-shadow: 0px 5px 8px rgba(216, 227, 241, 0.65);
}
.custom-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}
.custom-grid img:first-child {
    grid-area: 1 / 1 / 3 / 3;
}
.div2 .custom-grid img:nth-child(2) {
    grid-area: 1 / 3 / 2 / 4;
}
.custom-grid img:last-child {
    grid-area: 2 / 3 / 3 / 4;
}

@media screen and (max-width: 992px) {
    .custom-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }

    .custom-grid img:first-child {
        grid-area: 1 / 1 / 3 / 3;
    }
    .div2 .custom-grid img:nth-child(2) {
        grid-area: 3 / 1 / 4 / 2;
    }
    .custom-grid img:last-child {
        grid-area: 3 / 2 / 4 / 3;
    }
}
