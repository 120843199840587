.ch {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 31px;
    color: #292931;
}

/* .choose-room-btn::after{

    content: '';
    position: absolute;
    width: 2px;
    height: 3rem;
    left: 48%;
    right: 52%;
    margin: auto;
    top: 0;
    bottom: 0;
} */
.price--info {
    border-right: 2px solid black;
    padding-right:14px;
}
.choose-room-btn.black::after{
    background-color: #000;
}
.choose-room-btn.white::after{
    background-color: #fff;
}

.roomi {
    width: 440;
    height: 691;
}

.seeMore {
    color: #2F69FF;
}

#rightdiv {
    float: right;
}

#leftdiv {
    float: left;
}

.tablecon>table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 0.7em;
}


.borderColor {
    border-bottom-style: solid;
    border-bottom-color: #E7ECF2;
    border-bottom-width: 1px;
}
.fontdesc{
    font-family: 'DM Sans';
    font-style: normal;
}
.w-change {
    width: 14rem;
}
.arroysvg{
    fill: black;
}

@media only screen and (max-width: 600px) {

    .room {
        display: flex;
        flex-direction: column;
    }
    .price{
        font-size: 20px;
    }
    .roomi {
        width: 360px;   
    }

    .w-change {
        width: 10.3rem;
    }
    .blows{
        font-size: 18px;
    }
}