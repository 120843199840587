.color-value-1 {
    fill: #70dc6633;
}
.color-value-2 {
    fill: #c2dfc0;
}
.color-value-3 {
    fill: #70dc66;
}
.react-calendar-heatmap rect{
    height: 30px;
}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
    bottom: -5px !important;
}
