.pexelsPixabay534641Icon {
  position: absolute;
  top: 0;
  left: 591px;
  width: 409px;
  height: 417px;
  object-fit: cover;
}

.continueBrowsingButtonContainedText {
  cursor: pointer;
  background-color: #1976d2;
}

.buttonContainedText {
  cursor: pointer;
}

.frameDiv1,
.frameDiv2 {
  display: flex;
  justify-content: flex-start;
}

.frameDiv2 {
  flex-direction: column;
  align-items: flex-start;
  gap: 29px;
}

.frameDiv1 {
  position: absolute;
  top: 158px;
  left: 22px;
  flex-direction: row;
  align-items: center;
}

.vectorIcon {
  position: relative;
  width: 19px;
  height: 19px;
  flex-shrink: 0;
}

.frameButton {
  cursor: pointer;
  border: 0;
  padding: 13px;
  background-color: rgba(16, 16, 16, 0.5);
  position: absolute;
  top: 23px;
  left: 940px;
  border-radius: 74px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.frameDiv {
  position: relative;
  border-radius: 46px;
  background-color: #fff;
  width: 1000px;
  height: 417px;
  overflow: hidden;
  max-width: 90%;
  max-height: 90%;
}