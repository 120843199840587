.nav {
    max-width: 1350px;
    padding: 0 1rem;
}

#language-menu>.css-g3hgs1-MuiBackdrop-root-MuiModal-backdrop {
    background-color: rgba(0, 0, 0, 0.4) !important;
}

@media only screen and (max-width: 600px) {
    .respnav {
        column-gap: 0;
        column-gap: 14px;
    }

    /* .token {
        display: none;
    } */

    .nav {
        width: auto;
        padding-left: 10px;
        padding-right: 10px;
        background-color: white;
        border-bottom: 2px solid #E7E7E7;
    }

    .logoimg {
        width: 106px;
        height: 34px;
    }
}

.alphaIcon {
    background-color: #7221E2;
    border-radius: 5px;
    width: 90px;
    text-align: center;
    color: white;
    padding: 2px 0px;
    font-size: 18px;
    box-shadow: 1px 1px #000000bd;
    margin-left: 15px;
}

.nav-link-text {
    color: #000;
    text-decoration: none;
    font-weight: 600;
    padding: 0px 15px;
}

.userDetail-form {
    background: #ffffff;
    box-shadow: 0px 5px 8px rgba(216, 227, 241, 0.65);
    width: 430px;
    height: 420;
}

@media screen and (max-width: 767px) {
    .userDetail-form {
        width: 310px !important;
        height: 80%;
    }

    .inp {
        width: 300px !important;
        height: 45px !important;
    }

    .topspace {
        margin-top: 1em;
    }

    .fontWidth {
        width: 260px !important
    }
}

/* @media screen and (max-width: 425)  {
    .userDetail-form {
        width: 310px;
    }
} */