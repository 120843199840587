.groupIcon {
  position: relative;
  width: 130px;
  height: 45.89px;
  flex-shrink: 0;
}
.groupIcon1{
  position: relative;
}
.aboutUsDiv,
.connectWalletDiv {
  position: relative;
  font-weight: 500;
  display: inline-block;
  white-space: nowrap;
}
.connectWalletDiv {
  font-size: 18px;
  font-family: "DM Sans";
  color: #fff;
  text-align: center;
}
.frameButton,
.frameDiv2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.frameButton {
  cursor: pointer;
  border: 0;
  padding: 10px 20px;
  background-color: transparent;
  border-radius: 50px;
  background: linear-gradient(-84.64deg, #2f69ff, #c856ff);
  box-sizing: border-box;
}
.frameDiv2 {
  gap: 40px;
}
.frameDiv,
.frameDiv1 {
  display: flex;
  align-items: flex-start;
}
.frameDiv1 {
  flex-direction: row;
  justify-content: flex-start;
  gap: 735px;
}
.frameDiv {
  background-color: #f7f9fb;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  position: relative;
  width: 1440px;
  flex-direction: column;
  padding: 19px 37px;
  justify-content: center;
}
.professionalInterfaceB,
.standardInterfaceB {
  position: absolute;
  top: 2px;
  left: 0;
  letter-spacing: -0.01em;
  display: inline-block;
}
.professionalInterfaceB {
  left: 203px;
  color: #828282;
}
.dSwitchFormControlLabel {
  position: absolute;
  top: 0;
  left: 132px;
  cursor: pointer;
}
.groupDiv {
  position: absolute;
  top: 0.11px;
  left: 898px;
  width: 326px;
  height: 20.13px;
}
.frameDiv3 {
  position: relative;
  background-color: #f7f9fb;
  width: 1258px;
  height: 35px;
  flex-shrink: 0;
  overflow: hidden;
  font-size: 12px;
  color: #000;
  margin-top: 17px;
}
.groupIcon1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 842px;
  height: 365px;
  object-fit: cover;
}
.buyTradeOnDemandInventor {
  margin: 0;
  position: absolute;
  top: 38px;
  left: 33px;
  line-height: 150%;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 623.37px;
}
.groupDiv1 {
  position: relative;
  width: 842px;
  height: 365px;
  flex-shrink: 0;
}
.rectangleDiv {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 498px;
  height: 365px;
}
.sizeButonTextField {
  border: 0;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
}
.sizeButonDiv {
  position: relative;
}
.sizeButonTextField1 {
  border: 0;
  background-color: transparent;
  position: relative;
}
.frameDiv7 {
  position: absolute;
  top: 90px;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}
.groupDiv2,
.lineDiv {
  position: absolute;
  top: 23px;
  left: 23px;
  width: 452px;
  height: 160px;
}
.lineDiv {
  top: 204.5px;
  left: -1.5px;
  border-top: 3px solid #f7f9fb;
  box-sizing: border-box;
  width: 501px;
  height: 3px;
}
.showAllAvailableOptionsAnd {
  margin: 0;
  position: absolute;
  top: 2px;
  left: 32px;
  font-weight: 500;
  display: inline-block;
  width: 420px;
  height: 49px;
}
.radioButtonFormControlLabel {
  position: absolute;
  top: 4px;
  left: 0;
}
.groupDiv3 {
  position: relative;
  width: 452px;
  height: 49px;
  flex-shrink: 0;
}
.frameDiv8 {
  position: absolute;
  top: 229px;
  left: 26px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.searchDiv {
  position: relative;
  font-size: 17px;
  letter-spacing: -0.01em;
  font-weight: 500;
  font-family: Montserrat;
  color: #fff;
  text-align: center;
  display: inline-block;
}
.frameButton1,
.groupDiv4 {
  position: absolute;
  cursor: pointer;
}
.frameButton1 {
  border: 0;
  padding: 12px 29px;
  background-color: #292931;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.groupDiv4 {
  top: 298px;
  left: 26px;
  width: 116px;
  height: 45px;
}
.frameDiv6 {
  position: relative;
  width: 498px;
  height: 365px;
  flex-shrink: 0;
  font-size: 16px;
  color: #646465;
}
.frameDiv5 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  font-size: 30.92px;
  color: #0b1024;
}
.browseOurHottestDestination {
  position: absolute;
  top: 2px;
  left: 0;
  display: inline-block;
}
.image52Icon,
.rectangleDiv1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 325px;
}
.rectangleDiv1 {
  background-color: #fff;
  height: 439px;
}
.image52Icon {
  height: 250px;
  object-fit: cover;
}
.rectangleDiv2 {
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 100px;
  background: linear-gradient(-84.64deg, #2f8aaf, #5ddcae);
  width: 68px;
  height: 21px;
}
.popularB {
  position: absolute;
  top: 12px;
  left: 25px;
  display: inline-block;
}
.maskGroupDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 325px;
  height: 250px;
}
.dubai {
  position: relative;
  font-size: 24px;
  line-height: 31.99px;
  text-transform: capitalize;
  display: inline-block;
  color: #292931;
}
.vectorIcon {
  position: relative;
  width: 13px;
  height: 13px;
  flex-shrink: 0;
}
.may26May {
  margin: 0;
  position: relative;
  display: inline-block;
  width: 100%;
}
.frameDiv12 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}
.unitedArabEmirates {
  margin: 0;
  position: absolute;
  top: 0;
  left: 10.26px;
  display: inline-block;
  width: 100%;
}
.vectorIcon1,
.vectorIcon2 {
  position: absolute;
  height: 36.11%;
  width: 5.32%;
  top: 6.94%;
  right: 95.07%;
  bottom: 56.94%;
  left: -0.39%;
  max-width: 100%;
  max-height: 100%;
}
.vectorIcon2 {
  height: 12.78%;
  width: 2.48%;
  top: 18.06%;
  right: 96.49%;
  bottom: 69.17%;
  left: 1.03%;
}
.groupDiv8 {
  align-self: stretch;
  position: relative;
  height: 36px;
  flex-shrink: 0;
}
.div,
.fromP {
  position: relative;
  display: inline-block;
}
.fromP {
  margin: 0;
}
.div {
  font-size: 28px;
  line-height: 31.99px;
  color: #292931;
}
.frameDiv11 {
  position: absolute;
  top: 255.13px;
  left: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  text-align: left;
  font-size: 14px;
  color: #646465;
}
.groupDiv6 {
  position: relative;
  width: 325px;
  height: 439px;
  flex-shrink: 0;
  cursor: pointer;
}
.image10Icon,
.image53Icon {
  position: absolute;
  height: 128%;
  width: 147.69%;
  top: 0;
  right: -47.69%;
  bottom: -28%;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
.image10Icon {
  height: 104%;
  width: 106.46%;
  top: -0.4%;
  right: -3.08%;
  bottom: -3.6%;
  left: -3.38%;
  display: none;
}
.rectangleDiv4 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 100px;
  background: linear-gradient(-84.64deg, #2f8aaf, #5ddcae);
}
.popularB1 {
  position: absolute;
  height: 66.67%;
  width: 60.29%;
  top: 9.52%;
  right: 17.65%;
  bottom: 23.81%;
  left: 22.06%;
  display: inline-block;
}
.groupDiv10 {
  position: absolute;
  height: 8.4%;
  width: 20.92%;
  top: 4%;
  right: 76%;
  bottom: 87.6%;
  left: 3.08%;
}
.qatarDiv {
  position: absolute;
  top: 0;
  left: 16.8px;
  display: inline-block;
}
.vectorIcon4,
.vectorIcon5 {
  position: absolute;
  height: 72.22%;
  width: 19.7%;
  top: 13.89%;
  right: 81.23%;
  bottom: 13.89%;
  left: -0.93%;
  max-width: 100%;
  max-height: 100%;
}
.vectorIcon5 {
  height: 25.55%;
  width: 8.55%;
  top: 36.11%;
  right: 86.8%;
  bottom: 38.34%;
  left: 4.65%;
}
.groupDiv11 {
  position: absolute;
  top: 0;
  left: 0;
  width: 53.8px;
  height: 18px;
}
.frameDiv15 {
  position: relative;
  width: 53.8px;
  height: 18px;
  flex-shrink: 0;
}
.frameDiv10,
.frameDiv13 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameDiv13 {
  position: absolute;
  top: 270px;
  left: 20px;
  flex-direction: column;
  gap: 8px;
  text-align: left;
  font-size: 14px;
  color: #646465;
}
.frameDiv10 {
  flex-direction: row;
  gap: 20px;
}
.image10Icon1 {
  position: absolute;
  top: 4px;
  left: 0;
  width: 325px;
  height: 245px;
  object-fit: cover;
}
.rectangleDiv6 {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100px;
  background: linear-gradient(-84.64deg, #2f8aaf, #5ddcae);
  width: 68px;
  height: 21px;
}
.popularB2 {
  position: absolute;
  top: 2px;
  left: 15px;
  display: inline-block;
}
.groupDiv13 {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 68px;
  height: 21px;
}
.mar20Mar {
  position: relative;
  display: inline-block;
}
.vectorIcon7,
.vectorIcon8 {
  position: absolute;
  height: 72.22%;
  width: 22.17%;
  top: 13.89%;
  right: 78.87%;
  bottom: 13.89%;
  left: -1.05%;
  max-width: 100%;
  max-height: 100%;
}
.vectorIcon8 {
  height: 25.55%;
  width: 9.62%;
  top: 36.11%;
  right: 85.15%;
  bottom: 38.34%;
  left: 5.23%;
}
.groupDiv14 {
  position: relative;
  width: 47.8px;
  height: 18px;
  flex-shrink: 0;
}
.miamiBeach {
  position: relative;
  font-size: 24px;
  line-height: 31.99px;
  text-transform: capitalize;
  display: inline-block;
}
.frameDiv21 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  color: #646465;
}
.unitedStatesP {
  margin: 0;
  position: absolute;
  top: 0;
  left: 16.8px;
  display: inline-block;
  width: 100%;
}
.vectorIcon10,
.vectorIcon11 {
  position: absolute;
  height: 72.22%;
  width: 10.11%;
  top: 13.89%;
  right: 90.36%;
  bottom: 13.89%;
  left: -0.48%;
  max-width: 100%;
  max-height: 100%;
}
.vectorIcon11 {
  height: 25.55%;
  width: 4.39%;
  top: 36.11%;
  right: 93.23%;
  bottom: 38.34%;
  left: 2.39%;
}
.groupSection {
  position: relative;
  width: 104.8px;
  height: 18px;
  flex-shrink: 0;
  text-align: left;
  font-size: 14px;
  color: #646465;
  font-family: "DM Sans";
}
.b,
.fromDiv {
  position: relative;
  display: inline-block;
}
.fromDiv {
  color: #646465;
}
.b {
  font-size: 28px;
  line-height: 31.99px;
}
.frameDiv20 {
  position: absolute;
  top: 270px;
  left: 19px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
  text-align: left;
  font-size: 14px;
  color: #292931;
}
.groupDiv15 {
  position: absolute;
  top: 0;
  left: 0;
  width: 325px;
  height: 439px;
  cursor: pointer;
}
.frameDiv19 {
  position: relative;
  width: 325px;
  height: 439px;
  flex-shrink: 0;
  overflow: hidden;
}
.rectangleDiv9 {
  position: absolute;
  top: 0.25px;
  left: 0;
  background-color: #fff;
  width: 325px;
  height: 439px;
}
.image52Icon1,
.rectangleIcon {
  position: absolute;
  top: 0.13px;
  left: 0;
  width: 325px;
  height: 250px;
  object-fit: cover;
}
.rectangleIcon {
  top: 10px;
  left: 10px;
  border-radius: 100px;
  width: 68px;
  height: 21px;
}
.groupDiv18 {
  position: absolute;
  top: -0.12px;
  left: 0;
  width: 325px;
  height: 250px;
}
.groupIcon2 {
  position: relative;
  width: 10px;
  height: 13px;
  flex-shrink: 0;
}
.frameDiv23,
.frameDiv25 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.frameDiv23 {
  position: absolute;
  top: 255.13px;
  left: 19px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  text-align: left;
  font-size: 14px;
  color: #646465;
}
.groupDiv17 {
  position: relative;
  width: 325px;
  height: 439.25px;
  flex-shrink: 0;
  cursor: pointer;
}
.groupDiv25 {
  top: 0;
  width: 325px;
  height: 439px;
}
.bgDiv,
.frameDiv9,
.groupDiv25 {
  position: absolute;
  left: 0;
}
.frameDiv9 {
  top: 117.88px;
  width: 1360px;
  height: 486px;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  overflow-x: hidden;
  text-align: center;
  font-size: 11px;
  color: #fff;
}
.bgDiv {
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 8px;
  background-color: #fff;
}
.arrowIcon {
  position: absolute;
  height: 75%;
  width: 66.67%;
  top: 12.5%;
  right: 105.82%;
  bottom: 12.5%;
  left: -72.49%;
  max-width: 100%;
  max-height: 100%;
}
.arrowIcnSmallDiv {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.arrowIcnSmallCopyDiv {
  position: absolute;
  height: 60%;
  top: 20%;
  right: -16px;
  bottom: 20%;
  width: 24px;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.arrowBtnDiv {
  position: absolute;
  height: 100%;
  width: 23.53%;
  top: 0;
  right: 76.47%;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.arrowIcon1 {
  position: absolute;
  height: 75%;
  width: 66.67%;
  top: -87.5%;
  right: 2.51%;
  bottom: 112.5%;
  left: 30.82%;
  max-width: 100%;
  max-height: 100%;
}
.arrowIcnSmallDiv1 {
  position: absolute;
  height: 60%;
  top: 80%;
  right: 8px;
  bottom: -40%;
  width: 24px;
  transform: rotate(-90deg);
  transform-origin: 0 0;
}
.arrowBtnDiv1 {
  position: absolute;
  height: 100%;
  width: 23.53%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 76.47%;
  overflow: hidden;
}
.selectionIcon {
  position: absolute;
  height: 106.25%;
  width: 30.36%;
  top: -3.12%;
  right: 70.54%;
  bottom: -3.12%;
  left: -0.89%;
  max-width: 100%;
  max-height: 100%;
  opacity: 0.4;
  mix-blend-mode: normal;
}
.bulletIcon,
.bulletIcon1,
.bulletIcon2 {
  position: absolute;
  height: 50%;
  width: 14.29%;
  top: 25%;
  right: 78.57%;
  bottom: 25%;
  left: 7.14%;
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
}
.bulletIcon1,
.bulletIcon2 {
  right: 35.71%;
  left: 50%;
  opacity: 0.6;
  mix-blend-mode: normal;
}
.bulletIcon2 {
  right: 0;
  left: 85.71%;
  opacity: 0.4;
}
.bulletsBlackDiv,
.groupDiv27 {
  position: absolute;
  height: 40%;
  width: 32.94%;
  top: 30%;
  right: 33.53%;
  bottom: 30%;
  left: 33.53%;
}
.groupDiv27 {
  height: 6.62%;
  width: 12.5%;
  top: 0;
  right: 0;
  bottom: 93.38%;
  left: 87.5%;
}
.groupDiv5 {
  position: relative;
  width: 1360px;
  height: 603.88px;
  flex-shrink: 0;
}
.rectangleDiv16 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 325px;
  height: 440px;
}
.vectorIcon22,
.vectorIcon23 {
  position: absolute;
  height: 72.22%;
  width: 10.73%;
  top: 13.89%;
  right: 89.78%;
  bottom: 13.89%;
  left: -0.51%;
  max-width: 100%;
  max-height: 100%;
}
.vectorIcon23 {
  height: 25.55%;
  width: 4.66%;
  top: 36.11%;
  right: 92.81%;
  bottom: 38.34%;
  left: 2.53%;
}
.groupDiv29 {
  position: absolute;
  top: 334px;
  left: 20px;
  width: 98.8px;
  height: 18px;
}
.div6,
.mensFashionWeek {
  line-height: 31.99px;
  color: #292931;
  display: inline-block;
}
.mensFashionWeek {
  position: absolute;
  top: 270px;
  left: 20px;
  font-size: 24px;
  text-transform: capitalize;
}
.div6 {
  top: 388px;
  font-size: 28px;
}
.div6,
.frameDiv37,
.fromDiv2 {
  position: absolute;
  left: 20px;
}
.frameDiv37 {
  top: 309px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}
.fromDiv2 {
  top: 370px;
  display: inline-block;
}
.fashionWeekDiv {
  position: relative;
  width: 325px;
  height: 440px;
  flex-shrink: 0;
  cursor: pointer;
}
.vectorIcon25,
.vectorIcon26 {
  position: absolute;
  height: 72.22%;
  width: 11.42%;
  top: 13.89%;
  right: 89.12%;
  bottom: 13.89%;
  left: -0.54%;
  max-width: 100%;
  max-height: 100%;
}
.vectorIcon26 {
  height: 25.55%;
  width: 4.96%;
  top: 36.11%;
  right: 92.35%;
  bottom: 38.34%;
  left: 2.69%;
}
.groupDiv30 {
  position: absolute;
  top: 334px;
  left: 20px;
  width: 92.8px;
  height: 18px;
}
.abuDhabiQatar,
.fromDiv3 {
  position: absolute;
  top: 370px;
  left: 21px;
  display: inline-block;
}
.abuDhabiQatar {
  top: 0;
  left: 19px;
}
.vectorIcon28,
.vectorIcon29 {
  position: absolute;
  height: 72.22%;
  width: 8.22%;
  top: 13.89%;
  right: 92.17%;
  bottom: 13.89%;
  left: -0.39%;
  max-width: 100%;
  max-height: 100%;
}
.vectorIcon29 {
  height: 25.55%;
  width: 3.57%;
  top: 36.11%;
  right: 94.5%;
  bottom: 38.34%;
  left: 1.94%;
}
.groupDiv31 {
  position: absolute;
  top: 334px;
  left: 20px;
  width: 129px;
  height: 18px;
}
.div8 {
  position: absolute;
  top: 388px;
  left: 19px;
  font-size: 28px;
  line-height: 31.99px;
  color: #292931;
  display: inline-block;
}
.vectorIcon31,
.vectorIcon32 {
  position: absolute;
  height: 72.22%;
  width: 11.18%;
  top: 13.89%;
  right: 89.35%;
  bottom: 13.89%;
  left: -0.53%;
  max-width: 100%;
  max-height: 100%;
}
.vectorIcon32 {
  height: 25.55%;
  width: 4.85%;
  top: 36.11%;
  right: 92.51%;
  bottom: 38.34%;
  left: 2.64%;
}
.groupDiv32 {
  position: absolute;
  top: 334px;
  left: 19px;
  width: 94.8px;
  height: 18px;
}
.image10Div,
.rectangleIcon1 {
  position: absolute;
  top: -1px;
  left: -11px;
  width: 346px;
  height: 260px;
}
.rectangleIcon1 {
  top: 0;
  left: 0;
  border-radius: 100px;
  width: 68px;
  height: 21px;
  object-fit: cover;
}
.maskGroupDiv8 {
  position: absolute;
  top: 0;
  left: 0;
  width: 325px;
  height: 250px;
  text-align: center;
  font-size: 11px;
  color: #fff;
}
.veniceFilmFestival1 {
  position: absolute;
  top: 270px;
  left: 19px;
  font-size: 24px;
  line-height: 31.99px;
  text-transform: capitalize;
  display: inline-block;
  color: #292931;
}
.frameDiv35,
.frameDiv41 {
  position: absolute;
  top: 309px;
  left: 19px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}
.frameDiv35 {
  top: 80px;
  left: 0;
  width: 1352px;
  overflow-y: auto;
  align-items: flex-start;
  gap: 20px;
  overflow-x: hidden;
  font-size: 14px;
  color: #646465;
}
.bulletIcon3,
.bulletIcon4 {
  position: absolute;
  height: 50%;
  width: 14.29%;
  top: 25%;
  right: 78.57%;
  bottom: 25%;
  left: 7.14%;
  max-width: 100%;
  max-height: 100%;
}
.bulletIcon4 {
  right: 35.71%;
  left: 50%;
  opacity: 0.6;
  mix-blend-mode: normal;
}
.groupDiv39 {
  position: absolute;
  height: 7.69%;
  width: 12.5%;
  top: 0;
  right: 0;
  bottom: 92.31%;
  left: 87.5%;
}
.groupDiv28 {
  position: relative;
  width: 1360px;
  height: 520px;
  flex-shrink: 0;
}
.rateYourExperience,
.sliderDefaultChakraSlider {
  position: absolute;
  top: 91.43px;
  left: 33.01px;
}
.rateYourExperience {
  top: 35.21px;
  line-height: 31.56px;
  display: inline-block;
}
.textAreaOutlinedTextField {
  position: absolute;
  top: 139.01px;
  left: 32.52px;
}
.frameDiv49,
.image62Icon {
  position: relative;
  flex-shrink: 0;
}
.frameDiv49 {
  background-color: #fff;
  width: 548.01px;
  height: 333.43px;
  overflow: hidden;
}
.image62Icon {
  width: 782.02px;
  height: 339px;
  object-fit: cover;
}
.frameDiv4,
.frameDiv48 {
  display: flex;
  justify-content: flex-start;
}
.frameDiv48 {
  flex-direction: row;
  align-items: center;
  gap: 17px;
  font-size: 21.04px;
  color: #000;
  font-family: Roboto;
}
.frameDiv4 {
  flex-direction: column;
  align-items: flex-start;
  gap: 71px;
  text-align: left;
  font-size: 28px;
}
.rectangleDiv24,
.rectangleDiv25 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #dee1e4;
}
.rectangleDiv25 {
  height: 19.59%;
  top: 80.41%;
  background-color: #cfd3d7;
}
.frameIcon {
  position: absolute;
  height: 15.12%;
  width: 9.31%;
  top: 16.49%;
  right: 81.94%;
  bottom: 68.38%;
  left: 8.75%;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}
.loremIpsumDolorSitAmetCo {
  position: absolute;
  height: 16.49%;
  width: 19.58%;
  top: 37.11%;
  right: 71.67%;
  bottom: 46.39%;
  left: 8.75%;
  font-size: 12px;
  display: inline-block;
}
.about,
.partnersB,
.supportB {
  position: absolute;
  height: 11.34%;
  width: 16.04%;
  top: 13.4%;
  right: 45.21%;
  bottom: 75.26%;
  left: 38.75%;
  line-height: 31.99px;
  display: inline-block;
  color: #292931;
}
.partnersB,
.supportB {
  right: 27.29%;
  left: 56.67%;
}
.supportB {
  right: 9.31%;
  left: 74.65%;
}
.teamP {
  margin-block-start: 0;
  margin-block-end: 0;
}
.activitiesP {
  margin: 0;
}
.helpContactTermsCondition,
.howItWorksTeamInvestmentA,
.listYourPropertyFAQSubscri {
  position: absolute;
  height: 32.99%;
  width: 11.6%;
  top: 25.77%;
  right: 49.65%;
  bottom: 41.24%;
  left: 38.75%;
  font-size: 14px;
  line-height: 24px;
  display: inline-block;
}
.helpContactTermsCondition,
.listYourPropertyFAQSubscri {
  right: 31.74%;
  left: 56.67%;
}
.helpContactTermsCondition {
  right: 13.75%;
  left: 74.65%;
}
.groupIcon3 {
  position: absolute;
  height: 12.37%;
  width: 13.33%;
  top: 60.82%;
  right: 77.92%;
  bottom: 26.8%;
  left: 8.75%;
  max-width: 100%;
  max-height: 100%;
  opacity: 0.3;
}
.buk2022AllRightsReserve {
  position: absolute;
  height: 5.5%;
  width: 12.01%;
  top: 87.63%;
  right: 44.03%;
  bottom: 6.87%;
  left: 43.96%;
  font-size: 12px;
  color: #434354;
  display: inline-block;
}
.footer,
.tradingPlatformHome {
  position: relative;
  font-family: "DM Sans";
}
.footer {
  width: 1440px;
  height: 291px;
  flex-shrink: 0;
  text-align: left;
  font-size: 18px;
  color: #646465;
}
.tradingPlatformHome {
  background-color: #f7f9fb;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  text-align: center;
  font-size: 24px;
  color: #130a44;
}
@media screen and (max-width: 768px) {
  .frameDiv35,
  .frameDiv9 {
    flex-direction: column;
  }
}
@media screen and (max-width: 428px) {
  .frameDiv10 {
    flex-direction: column;
  }
}
