@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 0px;
}

*:focus {
  outline: none;
}

* {
  font-family: 'DM Sans' !important;
  
}
body {
  background-color: #f7f9fb;
  scroll-behavior: smooth;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.css-1wr7786-MuiSlider-root .MuiSlider-thumb[data-index='1'] img{
  transform: rotate(180deg)
}

.noScrollbar {
  overflow-x: scroll; /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.noScrollbar::-webkit-scrollbar {
    display: none;
}

.enlargeImg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-wrapper{
  display:flex;
  flex-direction: row;
  position: relative;
}
.css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root::before{
  border: none !important; 
}
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
  fill: #2F69FF !important;
}
.gradient-border{
  border-width: 2px !important;
  border-style: solid !important;
  border-image: linear-gradient(to right, #2F69FF, #C856FF) !important;
}
.gradientBorder {
  border: 1px solid;
  border-image: linear-gradient(to right, rgb(254, 169, 183), blue) 1 1;
}
#styled-select .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border: none !important;
  box-shadow: 0 4px 6px -1px #D8E3F1A6, 0 2px 4px -2px #D8E3F1A6 !important;
}

.react-tel-input > input {
  height: 100% !important;
  width: 100% !important;
}

.css-1s2u09g-control,
.css-1pahdxg-control {
  height: 100% !important;
}
.css-26l3qy-menu {
  z-index:100 !important;
}
.border-left {
  border-radius: 40px 0 0 40px;
}
.border-right {
  border-radius: 0 40px 40px 0;
}
input::placeholder {
  color: #7E7E7E !important;
}
textarea {
  width: 100%;
  border: 1px solid #e5e7eb;
  padding: 8px;
  border-radius: 4px;
  height: 160px;
}

/* .react-date-picker__button__input__input {
  -moz-user-input: none !important;
  user-input: none !important;
} */
.react-daterange-picker__inputGroup__input {
  user-select: none !important;
}
@media screen and (max-width: 992px) {
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    width: 100% !important;
  }
}
.react-datepicker-wrapper {
  max-width: 150px !important;
  border: 1px solid rgba(128, 128, 128, 0.738) !important;
  padding: 12px !important;
  border-radius: 4px !important;
}
.react-datepicker-wrapper > div > input {
  max-width: 110px !important;
}
.css-1wc0u5o-MuiPaper-root-MuiAccordion-root:before{
  display: none !important;
}