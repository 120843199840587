.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.navbar-logo-left {
  border-bottom: 2px solid #e7e7e7;
}

.navbar-logo-left-container {
  z-index: 5;
  width: 1030px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 15px 20px;
  background-color: transparent;
}

.navbar-logo-left-container.shadow-three {
  width: 100%;
  max-width: 1300px;
  margin-bottom: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.navbar-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-menu-two {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-link {
  margin-right: 5px;
  margin-left: 5px;
  padding: 5px 10px;
  font-family: Montserrat, sans-serif;
  color: #1a1b1f;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.25px;
  text-decoration: none;
}

.nav-link:hover {
  color: rgba(26, 27, 31, 0.75);
}

.nav-link:focus-visible {
  border-radius: 4px;
  outline-color: #0050bd;
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 2px;
  color: #0050bd;
}

.nav-link[data-wf-focus-visible] {
  border-radius: 4px;
  outline-color: #0050bd;
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 2px;
  color: #0050bd;
}

.nav-dropdown {
  margin-right: 5px;
  margin-left: 5px;
}

.nav-dropdown-toggle {
  padding: 5px 30px 5px 10px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.nav-dropdown-toggle:hover {
  color: rgba(26, 27, 31, 0.75);
}

.nav-dropdown-toggle:focus-visible {
  border-radius: 5px;
  outline-color: #0050bd;
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 2px;
  color: #0050bd;
}

.nav-dropdown-toggle[data-wf-focus-visible] {
  border-radius: 5px;
  outline-color: #0050bd;
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 2px;
  color: #0050bd;
}

.nav-dropdown-icon {
  margin-right: 10px;
}

.nav-dropdown-list {
  border-radius: 12px;
  background-color: #fff;
}

.nav-dropdown-list.w--open {
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-dropdown-link {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
}

.nav-dropdown-link:focus-visible {
  border-radius: 5px;
  outline-color: #0050bd;
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 2px;
  color: #0050bd;
}

.nav-dropdown-link[data-wf-focus-visible] {
  border-radius: 5px;
  outline-color: #0050bd;
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 2px;
  color: #0050bd;
}

.nav-divider {
  width: 1px;
  height: 22px;
  margin-right: 15px;
  margin-left: 15px;
  background-color: #e4ebf3;
}

.nav-link-accent {
  margin-right: 20px;
  margin-left: 5px;
  padding: 5px 10px;
  color: #1a1b1f;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.25px;
  text-decoration: none;
}

.nav-link-accent:hover {
  color: rgba(26, 27, 31, 0.75);
}

.button-primary {
  padding: 10px 15px;
  border-radius: 20px;
  background-color: #1a1b1f;
  background-image: -webkit-gradient(linear, left top, right top, from(#c856ff), to(#2f69ff));
  background-image: linear-gradient(90deg, #c856ff, #2f69ff);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.button-primary:hover {
  background-color: #32343a;
  color: #fff;
}

.button-primary:active {
  background-color: #43464d;
}

.body {
  background-color: #f7f9fb;
}

.container-2 {
  max-width: 1300px;
  padding-top: 0px;
  padding-bottom: 20px;
}

.grid-clone {
  margin-top: 20px;
  margin-bottom: 20px;
  -ms-grid-columns: 1.75fr 1fr;
  grid-template-columns: 1.4fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block {
  position: relative;
}

.text-block {
  position: absolute;
  left: 5%;
  top: 10%;
  right: 30px;
  bottom: auto;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 30px;
  line-height: 40px;
}

.div-block-2 {
  padding: 20px;
  background-color: #fff;
}

.container-3 {
  max-width: 1300px;
  padding: 20px;
}

.section {
  padding-top: 20px;
  padding-bottom: 20px;
}

.text-block-2 {
  font-family: Montserrat, sans-serif;
  color: #130a44;
  font-size: 18px;
  font-weight: 600;
}

.grid-2 {
  padding-top: 40px;
  padding-bottom: 40px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.div-block-3 {
  background-color: #fff;
  cursor: pointer;
}

.div-block-4 {
  background-color: #fff;
}

.div-block-4 {
  padding: 20px;
}

.text-block-3 {
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: Montserrat, sans-serif;
}

.from {
  padding-top: 10px;
  padding-bottom: 8px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.price {
  padding-top: 5px;
  padding-bottom: 8px;
  font-family: Montserrat, sans-serif;
  font-size: 28px;
  line-height: 20px;
  font-weight: 600;
}

.container-4 {
  max-width: 1100px;
}

.footer-dark {
  position: relative;
  padding: 50px 30px 15px;
  border-bottom: 1px solid #e4ebf3;
  background-color: #fff;
}

.container-5 {
  width: 100%;
  max-width: 1250px;
  margin-right: auto;
  margin-left: auto;
}

.footer-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.footer-content {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 70px;
  grid-row-gap: 40px;
  -ms-grid-columns: auto auto 1fr;
  grid-template-columns: auto auto 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.title-small {
  margin-bottom: 12px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.footer-link {
  margin-top: 12px;
  margin-bottom: 6px;
  font-family: Montserrat, sans-serif;
  color: #646465;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
}

.footer-link:hover {
  color: rgba(26, 27, 31, 0.75);
}

.footer-social-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 12px;
  margin-left: -12px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-social-link {
  margin-left: 12px;
}

.footer-divider {
  width: 100%;
  height: 1px;
  margin-top: 70px;
  margin-bottom: 15px;
  background-color: #e4ebf3;
}

.footer-copyright-center {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.grid-copy {
  margin-top: 20px;
  margin-bottom: 20px;
  -ms-grid-columns: 0.75fr 1fr;
  grid-template-columns: 0.75fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

/* .slide {
  background-image: url('../images/1.png');
  background-position: 0px 0px;
  background-size: auto;
} */

.title-small-2 {
  margin-bottom: 12px;
  color: #fff;
  font-size: 18px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.container-6 {
  margin-right: auto;
  margin-left: auto;
}

.footer-link-2 {
  margin-top: 12px;
  margin-bottom: 6px;
  color: #d6d6d6;
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
}

.footer-link-2:hover {
  color: #dbc1eb;
}

.footer-dark-2 {
  position: relative;
  padding: 50px 30px 15px;
  border-bottom: 1px solid #e4ebf3;
  background-color: #000;
  color: #fff;
}

.footer-content-2 {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 70px;
  grid-row-gap: 40px;
  -ms-grid-columns: auto auto 1fr;
  grid-template-columns: auto auto 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer-divider-2 {
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 15px;
  background-color: #282828;
}

.body-text {
  padding-top: 10px;
  font-size: 1rem;
  font-weight: 400;
}

.body-text.footer.small-text {
  max-width: 430px;
  padding-top: 10px;
  padding-left: 0px;
  font-size: 0.8rem;
}

.social-image {
  padding: 10px;
  color: #f8f8f8;
}

.footer-social-block-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

@media screen and (max-width: 991px) {
  .container {
    max-width: 728px;
  }

  .nav-menu-wrapper {
    background-color: transparent;
  }

  .nav-menu-two {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 10px;
    padding: 20px;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50px;
    background-color: #fff;
    box-shadow: 0 8px 50px 0 rgba(0, 0, 0, 0.05);
  }

  .nav-link {
    padding-right: 5px;
    padding-left: 5px;
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: absolute;
  }

  .menu-button {
    padding: 12px;
  }

  .menu-button.w--open {
    background-color: #a6b1bf;
    color: #fff;
  }

  .grid-2 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .container-5 {
    max-width: 728px;
  }

  .footer-content {
    grid-column-gap: 60px;
  }

  .image {
    -o-object-fit: cover;
    object-fit: cover;
  }

  .container-6 {
    max-width: 728px;
  }

  .footer-content-2 {
    grid-column-gap: 25px;
  }

  .body-text.footer.small-text {
    max-width: 250px;
  }
}

@media screen and (max-width: 767px) {
  .navbar-logo-left-container {
    max-width: 100%;
  }

  .navbar-brand {
    padding-left: 0px;
  }

  .nav-menu-two {
    padding-bottom: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 20px;
  }

  .nav-link {
    display: inline-block;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .nav-dropdown {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .nav-dropdown-toggle {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .nav-dropdown-list.shadow-three {
    box-shadow: 0 8px 50px 0 rgba(0, 0, 0, 0.05);
  }

  .nav-dropdown-list.shadow-three.w--open {
    position: relative;
  }

  .nav-dropdown-list.shadow-three.mobile-shadow-hide {
    box-shadow: none;
  }

  .nav-divider {
    width: 200px;
    height: 1px;
    max-width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .nav-link-accent {
    display: inline-block;
    margin-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .mobile-margin-top-10 {
    margin-top: 10px;
  }

  .grid-clone {
    -ms-grid-columns: 1.75fr;
    grid-template-columns: 1.75fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .div-block-2 {
    padding: 10px;
  }

  .footer-dark {
    padding-right: 15px;
    padding-left: 15px;
  }

  .footer-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer-content {
    margin-top: 40px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .footer-block {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer-link:hover {
    color: rgba(26, 27, 31, 0.75);
  }

  .footer-social-block {
    margin-top: 20px;
    margin-left: -20px;
  }

  .footer-social-link {
    margin-left: 20px;
  }

  .footer-divider {
    margin-top: 60px;
  }

  .grid-copy {
    -ms-grid-columns: 1.75fr;
    grid-template-columns: 1.75fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .slide {
    background-size: cover;
  }

  .footer-link-2:hover {
    color: rgba(26, 27, 31, 0.75);
  }

  .footer-dark-2 {
    padding-right: 15px;
    padding-left: 15px;
  }

  .footer-content-2 {
    margin-top: 40px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .footer-divider-2 {
    margin-top: 60px;
  }

  .body-text.footer.small-text {
    max-width: 500px;
  }
}

@media screen and (max-width: 479px) {
  .container {
    max-width: none;
  }

  .nav-menu-two {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .grid-clone {
    -ms-grid-columns: 1.5fr;
    grid-template-columns: 1.5fr;
  }

  .container-3 {
    padding: 10px;
  }

  .grid-2 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .container-5 {
    max-width: none;
  }

  .grid-copy {
    -ms-grid-columns: 1.5fr;
    grid-template-columns: 1.5fr;
  }

  .container-6 {
    max-width: none;
  }

  .footer-dark-2 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .footer-content-2 {
    margin-top: 20px;
    grid-row-gap: 29px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .footer-divider-2 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .body-text {
    font-size: 0.9rem;
  }

  .body-text.footer.small-text {
    max-width: 270px;
  }
}

#w-node-_3e37a75c-c043-8d24-66bb-f6904354c9fd-3fc478c7 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_938c8522-bbe5-5ab1-bc18-410e003b9435-3fc478c7 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6047cdf6-1b5a-31af-f4b6-c6913c38b941-3fc478c7 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_10be3560-9f62-89a9-0dfd-403497807495-3fc478c7 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_198be254-5902-2d05-2a23-887be15d156d-3fc478c7 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6c533dcf-f997-2a20-ab76-fbace487ba7c-3fc478c7 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_94c4133d-7315-e261-8583-8b5a0d670c12-3fc478c7 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_9f239c38-6873-3339-0fdf-7c3433ea8e68-3fc478c7 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_54450170-e4f1-ed7b-d8f1-a2f217c28be2-3fc478c7 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_819e799a-3c35-348c-a8cd-47cd1f3333f1-3fc478c7 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-d0359ef6-76ee-da87-88bd-3f2c34db9c62-3fc478c7 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-d0359ef6-76ee-da87-88bd-3f2c34db9c64-3fc478c7 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -webkit-box-ordinal-group: -9998;
  -webkit-order: -9999;
  -ms-flex-order: -9999;
  order: -9999;
}

#w-node-_86ede1d1-d4eb-2d7e-d8c1-f9815b7a6f59-3fc478c7 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_86ede1d1-d4eb-2d7e-d8c1-f9815b7a6f66-3fc478c7 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_86ede1d1-d4eb-2d7e-d8c1-f9815b7a6f6f-3fc478c7 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

@media screen and (max-width: 991px) {
  #w-node-_6047cdf6-1b5a-31af-f4b6-c6913c38b941-3fc478c7 {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-_10be3560-9f62-89a9-0dfd-403497807495-3fc478c7 {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-_198be254-5902-2d05-2a23-887be15d156d-3fc478c7 {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-_6c533dcf-f997-2a20-ab76-fbace487ba7c-3fc478c7 {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-_94c4133d-7315-e261-8583-8b5a0d670c12-3fc478c7 {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-_9f239c38-6873-3339-0fdf-7c3433ea8e68-3fc478c7 {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-_54450170-e4f1-ed7b-d8f1-a2f217c28be2-3fc478c7 {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }

  #w-node-_819e799a-3c35-348c-a8cd-47cd1f3333f1-3fc478c7 {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
  }
}