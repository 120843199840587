.app {
  background-color: #f7f9fb;
  padding: 0 1rem;
  max-width: 1350px;
  margin: 0 auto;
  min-height: 100vh;
}

.info-card {
  padding: 0 1rem;
  max-width: 1350px;
  margin: 0 auto;
}

.paragraph {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #242426;
}

