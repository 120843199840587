.heading {
    font-family: DM Sans;
    font-size: 50px;
    font-weight: 700;
    line-height: 65px;
    letter-spacing: 0em;
    text-align: center;
}

#inputHotelName {
    width: 80px;
}

/* search border */
.react-daterange-picker__wrapper {
    border: 0 !important;
}

.search-box1 {
    max-width: 1358px;
    width: 100%;
    height: 77px;
    background: #ffffff;
    box-shadow: 0px 5px 8px rgba(216, 227, 241, 0.65);
    border-radius: 40px;
    padding: 0 2rem;
}

.btn {
    width: 214px;
    height: 61px;
    background: #2f69ff;
    border-radius: 40px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #ffffff;
}

.save {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 55px;
    color: #ffffff;
}

.c {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7e7e7e;
}

.c1 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #434354;
}

.c2 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #434354;
}

.borderToCheck {
    border-left: 1.3px solid #2f69ff;
}

.rightbar1 {
    margin-left: 200px;
}

.him {
    display: none;
}

.search-boxm {
    display: none;
}

input::placeholder {
    font-size: 18px;
    color: #292931;
}

@media only screen and (max-width: 768px) {
    .heading {
        font-size: 27px;
    }

    input::placeholder {
        font-size: 16px;
        color: #292931;
    }

    input {
        width: 250px;
    }

    .search-box1 {
        display: none;
    }

    .hi {
        display: none;
    }

    .him {
        display: inline-block;
    }

    .btn {
        width: 340px;
    }

    .search-boxm {
        display: flex;
        flex-direction: column;
        width: 340px;
        margin: auto;
    }
}

@media only screen and (max-width: 992px) {
    .borderToCheck {
        border-left: none !important
    }
}

.react-daterange-picker__clear-button__icon,
.react-daterange-picker__button__icon {
    display: none !important;
}

.react-daterange-picker__inputGroup__day {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 21px !important;
    /* identical to box height */

    color: #434354 !important;
}

.react-daterange-picker__inputGroup__leadingZero,
.react-daterange-picker__inputGroup__month,
.react-daterange-picker__inputGroup__year {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    /* identical to box height */

    color: #434354 !important;
}

.react-calendar {
    background-color: #fbfcff !important;
}

.search-dropdown .dropdown-toggle::after {
    display: none !important;
}