.buybtn {
    display: none;
}
@media screen and (max-width: 992px) {
    .wifi,
    .parkingicon,
    .wallet,
    .buy {
        display: none;
    }
    .buybtn {
        display: block;
    }
}
