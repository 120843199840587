.color-1 {
    background-color: #e2f8e0;
}
.color-2 {
    background-color: #c2dfc0;
}
.color-3 {
    background-color: #70dc66;
}
.grid-cols-16{
    grid-template-columns :repeat(16, minmax(0, 1fr));
}