.sell {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 47px;
    text-align: center;
    color: #130a44;
}
.gradientBorder {
    border: 1px solid;
    border-image: linear-gradient(to right, rgb(254, 169, 183), blue) 1 1;
}
.borderlin {
    border: 1px solid rgba(200, 86, 255, 1);
}

.part1 {
    height: 536px;
}
.hotel-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 1rem;
}

.hotel-grid .div1 {
    grid-area: 1 / 1 / 4 / 5;
}
.hotel-grid .div2 {
    grid-area: 1 / 5 / 2 / 6;
}
.hotel-grid .div3 {
    grid-area: 2 / 5 / 3 / 6;
}
.hotel-grid .div4 {
    grid-area: 3 / 5 / 4 / 6;
}

.sale {
    max-width: 1367px;
}

.buttonClr {
    background-color: #2f69ff;
}

@media only screen and (min-width: 992px) {
    .dispnone1 {
        display: none;
    }
}

.textinsm {
    font-size: 30px;
}

.textsell {
    font-size: 18px;
}

.textsell1 {
    font-size: 15px;
}

.textcolorid {
    color: #2f69ff;
}

@media only screen and (max-width: 992px) {
    .dispnone {
        display: none;
    }

    .sale {
        width: 360px;
    }

    .parking {
        margin-left: 0;
    }

    .sell {
        font-size: 18px;
        padding-top: 12px;
        padding-bottom: 0px;
    }

    .iconsm {
        margin-bottom: 10px;
    }

    .inpusmt {
        padding-left: 0;
    }

    .hotel-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        gap: 1rem;
    }

    .hotel-grid .div1 {
        grid-area: 1 / 1 / 3 / 4;
    }
    .hotel-grid .div2 {
        grid-area: 3 / 1 / 4 / 2;
    }
    .hotel-grid .div3 {
        grid-area: 3 / 2 / 4 / 3;
    }
    .hotel-grid .div4 {
        grid-area: 3 / 3 / 4 / 4;
    }

    .usd1 {
        padding-left: 0;
    }

    .saling {
        display: flex;
        flex-direction: column-reverse;
    }

    /* .part1 {
            margin-left: 10px;
            margin-right: 10px;
            height: auto;
        } */

    .usd1 {
        padding-left: 10px;
    }

    .starsm {
        height: 16px;
        width: 16px;
    }

    .textsm {
        font-size: 10px;
    }

    .inpusmt {
        display: none;
    }

    .fontsmin {
        font-size: 10px;
    }

    .hotelhead {
        font-size: 17px;
        margin-top: 10px;
    }

    .tdwidth {
        width: auto;
    }

    .usd1 {
        margin-left: 80px;
    }
}
