.roomcard {
    width: 990px;
    height: 830px;
    background: #ffffff;
    box-shadow: 0px 5px 8px rgba(216, 227, 241, 0.65);
}

.sellcard {
    width: 388px;
    height: 533px;
    background: #ffffff;
    box-shadow: 0px 5px 8px rgba(216, 227, 241, 0.65);
    border: 1px solid;
    -webkit-border-image: -webkit-gradient(
        272.42deg,
        #2f69ff -3.48%,
        #c856ff 100%
    );
}

.ib {
    background: #ffffff;
    border: 1.55556px solid #adadad;
    border-radius: 6.22222px;
}

.sellcardmb {
    display: none;
}

.roomcardmb {
    display: none;
}

.eventcard {
    display: none;
}

.hidemb {
    display: none;
}

.parent-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  gap: 0.5rem;
}

.parent-grid .div0 {
  grid-area: 1 / 1 / 5 / 5;
}
.parent-grid .div1 {
  grid-area: 5 / 1 / 6 / 2;
}
.parent-grid .div2 {
  grid-area: 5 / 2 / 6 / 3;
}
.parent-grid .div3 {
  grid-area: 5 / 3 / 6 / 4;
}
.parent-grid .div4 {
  grid-area: 5 / 4 / 6 / 5;
}

@media screen and (max-width: 992px) {
    .custom-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }

    .custom-grid img:first-child {
        grid-area: 1 / 1 / 3 / 3;
    }
    .div2 .custom-grid img:nth-child(2) {
        grid-area: 3 / 1 / 4 / 2;
    }
    .custom-grid img:last-child {
        grid-area: 3 / 2 / 4 / 3;
    }
}

@media only screen and (max-width: 600px) {
    .tmb {
        display: none;
    }
    .sellcardmb {
        display: flex;
        width: 370px;
        height: 533px;
        background: #ffffff;
        box-shadow: 0px 5px 8px rgba(216, 227, 241, 0.65);
        margin: auto;
        padding: 0 10px;
        border: 1px solid;
        -webkit-border-image: -webkit-gradient(
            272.42deg,
            #2f69ff -3.48%,
            #c856ff 100%
        );
    }
    .roomcardmb {
        display: flex;
        width: 370px;
        height: 550px;
        background: #ffffff;
        box-shadow: 0px 5px 8px rgba(216, 227, 241, 0.65);
        margin: auto;
    }
    .eventcard {
        display: flex;
        width: 370px;
        background: #ffffff;
        box-shadow: 0px 5px 8px rgba(216, 227, 241, 0.65);
        margin: auto;
        flex-direction: column;
    }
    .hidemb {
        display: flex;
        width: 370px;
        background: #ffffff;
        box-shadow: 0px 5px 8px rgba(216, 227, 241, 0.65);
        margin: auto;
    }
}
